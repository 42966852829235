<template>
  <div>
    <!--begin::customer-->
    <div>
          <h3 class="card-label">
            {{ $t('sales_periods.sales_periods') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('sales_periods.sales_commission') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <multiselect v-model="sales_commission"
                               :placeholder="$t('sales_periods.sales_commission')"
                               label="name"
                               track-by="id"
                               :options="commision_sales_list"
                               :multiple="false"
                               :taggable="true"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/salestargetcommission/sales-commissions/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.commision_sales_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.commision_sales_id[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('sales_periods.employee') }}</label>
                <div class="input-group mb-3">
                  <multiselect v-model="employee"
                               :placeholder="$t('sales_periods.employee')"
                               :class="validation && validation.employee_ids ? 'is-invalid' : ''"
                               label="full_name"
                               track-by="id"
                               :options="employee_list"
                               :multiple="true"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getEmployees($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/employees/employees/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters')+' '+$t('sales_periods.employee') }}.</span>

                <span v-if="validation && validation.employee_ids" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.employee_ids[0] }}
                            </span>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_periods.from_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.from_date" class="form-control" :class="validation && validation.from_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.from_date" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.from_date[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_periods.to_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.to_date" class="form-control" :class="validation && validation.to_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.to_date" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.to_date[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox size="lg" v-model="data.employee_selection" name="check-button" switch>{{ $t('sales_periods.employee_selection') }}</b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox size="lg" v-model="data.rules_selection" name="check-button" switch>{{ $t('sales_periods.rules_selection') }}</b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>




        <div class="pr-0 pl-0">
          <div class="row">
            <div class="col-lg-6 mt-10">
              <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
<!--              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>-->
            </div>
          </div>
        </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-sales-periods",
  data() {
    return {
      mainRoute: 'salestargetcommission/sales-periods',
      mainRouteDependency: 'base/dependency',


      data: {
        from_date: null,
        to_date: null,
        employee_selection: false,
        employee_ids: [],
        rules_selection: false,
        commision_sales_id: null,
        status: true,
      },
      employee: [],
      idEditing: this.$route.params.id,
      isEditing: false,

      commision_sales_list: [],
      employee_list: [],
      sales_commission: null,
      validation: null,

    };
  },
  watch: {
    employee: function (val) {
      this.data.employee_ids = [];
      if (val) {
        val.map((row) => {
          this.data.employee_ids.push(row.id);
        });
      }
    },
    sales_commission: function (val) {
      if (val) {
        this.data.commision_sales_id = val.id;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'sales-periods.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'sales-periods.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.from_date = response.data.data.from_date;
        this.data.to_date = response.data.data.to_date;
        this.data.employee_selection = response.data.data.employee_selection;
        this.data.employee_ids = response.data.data.employee_ids;
        this.data.rules_selection = response.data.data.rules_selection;
        this.data.commision_sales_id = response.data.data.commision_sales_id;
        this.sales_commission = response.data.data.sales_commission;
        this.data.status = response.data.data.status;

        this.employee = response.data.data.employees;


      });
    },


    getEmployees(filter) {
      if(filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/employees", {params:{name: filter}}).then((response) => {
          this.employee_list = response.data.data;
        });
    },
    getCommissionSales() {
      ApiService.get(this.mainRouteDependency + "/commision_sales").then((response) => {
        this.commision_sales_list = response.data.data;
      });
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_periods"), route: '/salestargetcommission/sales-periods'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    // this.getEmployees();
    this.getCommissionSales();

    if (this.idEditing) {
      this.getData();
    }

  },
};
</script>